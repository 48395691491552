import { atom } from "jotai"
import { GridFilterModel } from "@mui/x-data-grid-pro"

export enum RentingPeriodGeneralPage {
    General,
    Configurations,
}

export enum ExpensesPages {
    ADDED,
    PENDING_EXPENSES,
    RECURRING_EXPENSES,
}

export enum PropertiesPageView {
    Table,
    Grid,
}

export enum PropertiesPage {
    Active,
    Archived,
}

export enum DashboardPages {
    General,
    Users,
}
export enum RentingPeriodsPages {
    All,
    Accepted,
    Pending,
    Finished,
    Declined,
    EndingSoon,
}
export enum ExpensesFilteredOptions {
    All,
    GeneralExpense,
    Rent,
    SecurityDeposit,
    Overdue,
    MoreThanOneIncomeAssigned,
    Fees,
    Damages,
}

export enum IncomesFilteredOptions {
    All,
    Expenses,
    Rent,
    SecurityDeposit,
    Pending,
    NoAssignedExpenses,
    MoreThanOneExpenseAssigned,
    AcceptedFromExternalSystem,
    Fees,
    Damages,
}

export enum JournalFilteredOptions {
    All,
    AddedByOwner,
    AddedBySystem,
}

export enum PropertyPages {
    GENERAL,
    NOTES,
    EXPENSES,
    INCOME,
    METERS,
    TENANTS,
    INSURANCE,
    DOCUMENTS,
    REVISION,
    MARKETING,
    CONFIGURATIONS,
}

export enum TenantsPage {
    GENERAL,
    EXPENSES,
    INCOMES,
    DOCUMENTS,
    JOURNAL,
    CONFIGURATIONS,
    C168,
    PAYMENT_NOTIFICATIONS,
}

export enum SettingsPages {
    General,
    Notifications,
    Security,
}
export enum TenantSettingsPages {
    General,
    Security,
}

export enum RentingPeriodsFilteredOptions {
    All,
    Accepted,
    Pending,
    Finished,
    Declined,
    EndingSoon,
}

export const expensesPageAtom = atom<ExpensesPages>(ExpensesPages.ADDED)
export const settingsPageAtom = atom<SettingsPages>(SettingsPages.General)
export const tenantSettingsPageAtom = atom<TenantSettingsPages>(TenantSettingsPages.General)
export const rentingPeriodGeneralPageAtom = atom<RentingPeriodGeneralPage>(RentingPeriodGeneralPage.General)
export const propertyDetailsPage = atom<PropertyPages>(PropertyPages.GENERAL)
export const rentingPeriodDetailsPage = atom<TenantsPage>(TenantsPage.GENERAL)
export const propertiesPageAtom = atom<PropertiesPage>(PropertiesPage.Active)
export const dashboardTableAtom = atom<DashboardPages>(DashboardPages.General)
export const propertiesFilterAtom = atom<string | undefined>(undefined)
export const rentingPeriodPageAtom = atom<RentingPeriodsPages>(RentingPeriodsPages.Accepted)
export const expensesFilterState = atom<GridFilterModel>({ items: [] })
export const rentingPeriodFilterState = atom<GridFilterModel>({ items: [] })
export const incomesFilterState = atom<GridFilterModel>({ items: [] })
export const journalPageAtom = atom<JournalFilteredOptions>(JournalFilteredOptions.All)
export const journalsFilterState = atom<GridFilterModel>({ items: [] })
export const propertiesPageViewAtom = atom<PropertiesPageView>(Number(localStorage.getItem("propertiesPage")) ?? PropertiesPageView.Grid)
export const tutorialAtom = atom<{ url: string; guideId: string; title: string } | undefined>(undefined)
