import React, { Fragment, useCallback, useMemo } from "react"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { useDispatch, useSelector } from "../../../redux/store"
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF, GridActionsCellItem, GridColDef, GridRowParams } from "@mui/x-data-grid-pro"
import { AppContext, CustomDataGrid, Iconify, Label, LightTooltip, LoadingIcon, useLocales } from "rentzz"
import ActionWithTooltip from "../../../utils/ActionWithTooltip"
import { useTheme } from "@mui/material/styles"
import { arrayOfNumbersToPeriod } from "../../../utils/dateMagic"
import { useMetersQueryFn } from "../../../queries/meters"
import NoDataPage from "../../../components/NoDataPage"
import { DateTime } from "luxon"
import { ConfiguredMeterProvider, OwnerPropertyMeter, ProviderStatus, ProviderType, TenantPropertyMeter } from "../../../redux/slices/AddProperty"
import { getUnitFromId, useUnitsQueryFn } from "../../../queries/units"
import { useIntl } from "react-intl"
import NoPhotographyIcon from "@mui/icons-material/NoPhotography"
import CameraAltIcon from "@mui/icons-material/CameraAlt"
import ModeEditIcon from "@mui/icons-material/ModeEdit"
import DeleteIcon from "@mui/icons-material/Delete"
import { DESKTOP_ICON_SIZE } from "../../../utils/helpers"
import { PermissionType, usePermissions } from "../../../hooks/usePermissions"
import MeterReadingsPanel from "./MetersHistory/MeterReadingsPanel"
import CustomDetailPanelToggle from "./CustomDetailPanelToggle"
import { Box, Button, IconButton, Typography } from "@mui/material"
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"
import InfoIcon from "@mui/icons-material/Info"
import { useFeatureIsOn } from "@growthbook/growthbook-react"
import { UserFlags, useTenantRentingPeriodsQuery, useUserDataQueryFn } from "../../../queries/userData"
import CustomToolbar from "../../../components/CustomToolbar"
import MaintainingValueWhenNoReadingSentForm from "./MaintainingValueWhenNoReadingSentForm"

export default function DesktopMetersList() {
    const intl = useIntl()
    const theme = useTheme()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const { currentPropertyId, context, currentRentingPeriodId } = useSelector((state) => state.appState)
    const { data: meters, isLoading: areMetersLoading } = useMetersQueryFn()
    const { data: unitData } = useUnitsQueryFn()
    const { canWrite } = usePermissions(PermissionType.Meters)
    const { data: user } = useUserDataQueryFn()
    const areMetersProvidersEnabled = useFeatureIsOn(UserFlags.MeterProviders.toString())
    const { data: tenantRentingPeriods } = useTenantRentingPeriodsQuery()
    const isWhitelabel = useFeatureIsOn(UserFlags.WhiteLabel.toString())

    const getIconColor = useCallback((status: ProviderStatus | undefined) => {
        if (status === ProviderStatus.Pending) return "warning"
        if (status === ProviderStatus.Rejected) return "error"
        return "success"
    }, [])

    const getTooltipTitle = useCallback(
        (currentProvider: ConfiguredMeterProvider | undefined) => {
            if (currentProvider == undefined) return
            if (currentProvider.status === ProviderStatus.Active) return translate("active_provider")
            const reason = currentProvider.rejectReason != undefined ? currentProvider.rejectReason : ""
            return translate(`rejected_provider_tooltip-${currentProvider.status}${reason}`)
        },
        [translate],
    )

    const getValue = useCallback(
        (value: number, meterId?: number) => {
            if (value === -1) return "-"

            if (context === AppContext.Tenant) {
                const currentMeter = (meters as TenantPropertyMeter[])?.find((meter) => meter.id === meterId)
                if (!currentMeter?.unitId) return ""
                return `${intl.formatNumber(value as number, {
                    style: "decimal",
                    useGrouping: false,
                })} ${getUnitFromId(unitData, currentMeter.unitId).code}`
            }

            if (context === AppContext.Owner) {
                const currentMeter = (meters as OwnerPropertyMeter[])?.find((meter) => meter.id === meterId)
                if (!currentMeter?.unitId) return ""
                return `${intl.formatNumber(value as number, {
                    style: "decimal",
                    useGrouping: false,
                })} ${getUnitFromId(unitData, currentMeter.unitId).code}`
            }
        },
        [meters, unitData, intl, context],
    )

    const metersColumns = useMemo(() => {
        const columns: GridColDef<OwnerPropertyMeter | TenantPropertyMeter>[] = [
            {
                ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
                renderCell: (params) => {
                    return <CustomDetailPanelToggle id={params.id} value={params.value} tooltipText={"view_history"} />
                },
            },
            {
                field: "name",
                type: "string",
                minWidth: 150,
                headerAlign: "center",
                align: "center",
                flex: 0.6,
                headerName: translate("meter_name"),
            },
            {
                field: "initialValue",
                minWidth: 100,
                headerAlign: "center",
                align: "center",
                flex: 0.5,
                type: "string",
                headerName: translate("initial_index"),
                valueFormatter: (value, row) => {
                    return getValue(value, row.id as number)
                },
            },
            {
                field: "currentValue",
                minWidth: 100,
                headerAlign: "center",
                align: "center",
                flex: 0.5,
                type: "string",
                headerName: translate("last_index"),
                valueFormatter: (value, row) => {
                    return getValue(value, row.id as number)
                },
            },
            {
                field: "lastModified",
                type: "date",
                minWidth: 100,
                flex: 0.5,
                headerAlign: "center",
                align: "center",
                headerName: translate("date_last_modified"),
                filterable: true,
                valueFormatter: (value) => (value as DateTime)?.toLocaleString(DateTime.DATE_SHORT),
            },
            {
                field: "configuredProvider",
                type: "number",
                minWidth: 120,
                headerAlign: "center",
                align: "center",
                flex: 0.5,
                headerName: translate("provider"),
                renderCell: (v) => {
                    if (v.value == null)
                        return (
                            <LightTooltip title={translate("add_new_provider")} arrow>
                                <IconButton
                                    disabled={user?.roles == null || !canWrite}
                                    onClick={() => {
                                        dispatch(setModalOpen(AppModals.AddMeterProvider))
                                        dispatch(setEditingItem({ providerType: ProviderType.Meter, id: v.api.getRow(v.id).id }))
                                    }}
                                >
                                    <AddCircleOutlineIcon color={"primary"} sx={{ fontSize: DESKTOP_ICON_SIZE }} />
                                </IconButton>
                            </LightTooltip>
                        )
                    return (
                        <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                            <Typography variant={"body2"}>{v.value.providerName}</Typography>
                            {
                                <IconButton>
                                    <LightTooltip
                                        title={
                                            <Box display={"flex"} flexDirection={"column"} gap={1}>
                                                <Typography variant={"body2"}>{getTooltipTitle(v.value)}</Typography>
                                                <Box display='flex' flexDirection='column' alignItems={"center"}>
                                                    {v.value?.fields?.map((f: any) => (
                                                        <Fragment key={f.name}>
                                                            <Typography variant={"body2"} fontWeight='bold'>
                                                                {translate(f.label)}
                                                            </Typography>
                                                            <Typography variant={"body2"} sx={{ maxWidth: 230 }}>
                                                                {f.value}
                                                            </Typography>
                                                        </Fragment>
                                                    ))}
                                                </Box>
                                                <Button
                                                    disabled={user?.roles == null || !canWrite}
                                                    onClick={() => {
                                                        dispatch(setModalOpen(AppModals.DeleteProvider))
                                                        dispatch(
                                                            setEditingItem({
                                                                providerId: v.value.id,
                                                                providerType: ProviderType.Meter,
                                                                name: v.value.providerName,
                                                            }),
                                                        )
                                                    }}
                                                    size={"small"}
                                                    variant={"contained"}
                                                    color={"error"}
                                                >
                                                    {translate("delete")}
                                                </Button>
                                            </Box>
                                        }
                                        arrow
                                    >
                                        <InfoIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} color={getIconColor(v.value.status)} />
                                    </LightTooltip>
                                </IconButton>
                            }
                        </Box>
                    )
                },
            },
            {
                field: "reportingPeriod",
                type: "number",
                minWidth: 100,
                headerAlign: "center",
                align: "center",
                flex: 0.5,
                headerName: translate("reading_period"),
                sortable: false,
                renderCell: (v) => {
                    return <Label color={"info"}>{arrayOfNumbersToPeriod(v.value)?.join(", ")}</Label>
                },
            },
            {
                field: "isMaintainingValueWhenNoReadingSent",
                type: "number",
                minWidth: 100,
                headerAlign: "center",
                align: "center",
                flex: 0.5,
                headerName: translate("is-maintaining-value-when-no-reading-sent"),
                sortable: false,
                renderCell: (v) => {
                    return <MaintainingValueWhenNoReadingSentForm meterId={Number(v!.id)} propertyId={v.api.getRow(v.id).propertyId} />
                },
            },
        ]

        if (context === AppContext.Tenant)
            return columns.filter(
                (c) =>
                    c.field !== "initialValue" &&
                    c.field !== "configuredProvider" &&
                    c.field !== "lastModified" &&
                    c.field !== "isMaintainingValueWhenNoReadingSent",
            )

        if (!areMetersProvidersEnabled && isWhitelabel) {
            return columns.filter((c) => c.field !== "configuredProvider")
        }

        if (!areMetersProvidersEnabled && !isWhitelabel) {
            return columns.filter((c) => c.field !== "configuredProvider" && c.field !== "isMaintainingValueWhenNoReadingSent")
        } else if (!isWhitelabel && areMetersProvidersEnabled) {
            return columns.filter((c) => c.field !== "isMaintainingValueWhenNoReadingSent")
        }

        return columns
    }, [meters, translate, getValue, areMetersProvidersEnabled, context])

    const actionsColumn = useMemo(
        (): GridColDef => ({
            field: "actions",
            resizable: false,
            minWidth: context === AppContext.Tenant ? 50 : 100,
            flex: context === AppContext.Tenant ? 0.3 : 0.7,
            type: "actions",
            headerName: translate("expenses.actions"),
            getActions: (params: GridRowParams) => [
                <ActionWithTooltip
                    key='info'
                    hidden={!params.row.canWrite}
                    element={
                        <GridActionsCellItem
                            key='info'
                            icon={
                                params.row.requirePhotoForReading ? (
                                    <CameraAltIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} color={"primary"} />
                                ) : (
                                    <NoPhotographyIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} color={"warning"} />
                                )
                            }
                            label={translate("edit_meter")}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                if (context === AppContext.Tenant) return
                                event.stopPropagation()
                                dispatch(setEditingItem({ ...params.row, propertyId: currentPropertyId }))
                                dispatch(setModalOpen(AppModals.EditMeterPhotoRequired))
                            }}
                            sx={{ p: 1, color: params.row.requirePhotoForReading ? theme.palette.primary.main : theme.palette.grey["600"] }}
                            disabled={!params.row.canWrite}
                        />
                    }
                    tooltipText={params.row.requirePhotoForReading ? translate("tenant_must_sent_photo") : translate("tenant_must_not_sent_photo")}
                />,

                <ActionWithTooltip
                    key='info'
                    hidden={!params.row.canWrite}
                    element={
                        <GridActionsCellItem
                            key='info'
                            icon={<Iconify icon={"mdi:numbers"} sx={{ fontSize: DESKTOP_ICON_SIZE }} color={"primary"} />}
                            label={translate("edit_meter")}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                if (context === AppContext.Tenant) return
                                event.stopPropagation()
                                dispatch(setEditingItem({ ...params.row, propertyId: currentPropertyId }))
                                dispatch(setModalOpen(AppModals.HandleReadingIntegerNumber))
                            }}
                            sx={{ p: 1, color: params.row.requireInteger ? theme.palette.primary.main : theme.palette.grey["600"] }}
                            disabled={!params.row.canWrite}
                        />
                    }
                    tooltipText={params.row.requireInteger ? translate("tenant_must_sent_integer_number") : translate("tenant_not_integer_number")}
                />,
                ActionWithTooltip({
                    element: (
                        <GridActionsCellItem
                            color='primary'
                            icon={<AddCircleOutlineIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                dispatch(setEditingItem({ id: params.row.id }))
                                dispatch(setModalOpen(AppModals.AddNewIndex))
                            }}
                            label={translate("add")}
                            sx={{ p: 1 }}
                        />
                    ),
                    hidden:
                        context === AppContext.Owner ||
                        (tenantRentingPeriods?.find((r) => r.rentingPeriodId === currentRentingPeriodId)?.to ?? DateTime.now()) < DateTime.now(),
                    tooltipText: translate("add_reading"),
                    key: "add",
                }),
                <ActionWithTooltip
                    key='edit'
                    element={
                        <GridActionsCellItem
                            key='edit'
                            color='primary'
                            icon={<ModeEditIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            label={translate("grid_actions.edit")}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                dispatch(setModalOpen(AppModals.EditMeter))
                                dispatch(setEditingItem(params.row))
                            }}
                            sx={{ p: 1 }}
                        />
                    }
                    tooltipText={translate("edit_meter")}
                    hidden={!params.row.canWrite}
                />,
                <ActionWithTooltip
                    element={
                        <GridActionsCellItem
                            hidden={!params.row.canWrite}
                            key='delete'
                            color={"error"}
                            icon={<DeleteIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                dispatch(setModalOpen(AppModals.DeleteMeter))
                                dispatch(setEditingItem({ ...params.row, propertyId: params.row.propertyId }))
                            }}
                            label={translate("grid_actions.delete")}
                            sx={{ p: 1 }}
                        />
                    }
                    tooltipText={""}
                    key={"delete"}
                    hidden={!params.row.canDelete}
                />,
            ],
        }),
        [translate],
    )
    const getMetersPanel = useCallback(
        (row: GridRowParams<(TenantPropertyMeter & { canWrite: boolean }) | (OwnerPropertyMeter & { canWrite: boolean })>) => {
            return <MeterReadingsPanel currentMeter={row.row} />
        },
        [],
    )

    if (areMetersLoading) return <LoadingIcon />

    return (
        <>
            <CustomToolbar
                onAddClick={context === AppContext.Owner && canWrite ? () => dispatch(setModalOpen(AppModals.AddMeter)) : undefined}
                numberOfItems={meters?.length}
                buttonText={"add_meter"}
            />
            <CustomDataGrid
                columns={metersColumns}
                rows={meters ?? []}
                components={{
                    noRowsOverlay: () => (
                        <NoDataPage icon={"mdi:timer-cancel"} noDataText={context === AppContext.Owner ? "no_meters" : "tenant_no_meters"} />
                    ),
                }}
                infoColumn={[]}
                idKey={"id"}
                actionsColumn={[actionsColumn]}
                getDetailPanelContent={getMetersPanel}
            />
        </>
    )
}
