import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AppContext } from "rentzz"
import Api from "../../api/Api"

export enum AppModals {
    AddProperty,
    EditProperty,
    AddMeter,
    AddIncome,
    AddExpense,
    CreateRentingPeriod,
    MeterValuesHistory,
    LinkExpenseToIncomeAsOwner,
    LinkExpenseToIncomeAsTenant,
    EditIncome,
    AddPayment,
    EditPayment,
    EditExpense,
    DeleteExpense,
    DeletePendingExpense,
    DeleteIncome,
    DeleteTenant,
    DeleteProperty,
    DeletePayment,
    DeleteMeter,
    DeleteFile,
    AddDocument,
    Settings,
    DeleteContract,
    HandleArchivedContractTemplates,
    AddInsurance,
    DeleteInsurance,
    EditInsurance,
    EditInsuranceNotification,
    EditMeterPhotoRequired,
    EditRentingPeriodDetails,
    TenantDetails,
    InviteTenant,
    HandleIncomeStatus,
    EntityFileManager,
    ExpenseDetails,
    IncomeDetails,
    InsuranceDetails,
    PaymentDetails,
    MeterDetails,
    AddNewIndex,
    EditMeter,
    EditReading,
    ResendInvitation,
    RemoveInvitation,
    Contact,
    AddNote,
    EditNote,
    DeleteNote,
    NoteDetails,
    NoteStatus,
    StatusMeterDetails,
    AddRecurringExpense,
    DeleteRecurringExpense,
    EditRecurringExpense,
    RecurringExpenseDetails,
    InviteManager,
    ManagerDetails,
    ResendManagerInvitation,
    RemoveManagerInvitation,
    AssignedIncomes,
    AssignedExpenses,
    HandleArchivedProperties,
    EditPropertyName,
    DeleteProvider,
    ProviderDetails,
    AddExpenseProvider,
    PendingExpenseDetails,
    PendingExpenseStatus,
    EditPropertyLocation,
    ExportDetailsSheet,
    AddMeterProvider,
    EditUserData,
    OblioDetails,
    DeleteOblioAccount,
    EditExternalSystemCodeName,
    Help,
    AddLabel,
    DeleteLabel,
    EditLabel,
    AddCustomProvider,
    DeleteCustomProvider,
    EditCustomProvider,
    LabelDetails,
    CustomProviderDetails,
    PendingReadings,
    EditSection,
    AddSection,
    DeleteSection,
    SectionDetails,
    AddTask,
    RemoveAllTasks,
    TaskDetails,
    EditTask,
    DeleteTask,
    TaskComments,
    AcceptOnTenantBehalf,
    DeactivateSendingExpenseToTenant,
    AddJournal,
    EditJournal,
    DeleteJournal,
    JournalDetails,
    BlockUser,
    JournalItemDetails,
    DeleteMeterValue,
    SectionFiles,
    DashboardItemsPreview,
    PdfViewer,
    ExcelViewer,
    AddInvoicingConfiguration,
    EditInvoicingConfiguration,
    DeleteInvoicingConfiguration,
    InvoicingConfigurationDetails,
    CreateInvoice,
    AddC186Address,
    DeleteC168Address,
    C168AddressDetails,
    NotificationsList,
    EditRevision,
    DeleteRevision,
    AddRevision,
    RevisionDetails,
    UpdateRevisionStatus,
    UpdateExpenseBalanceStatus,
    AddGroup,
    EditGroupProperties,
    DeleteGroup,
    GroupDetails,
    StopAutomaticReadingSending,
    PendingReadingDetails,
    DeleteNotificationTemplate,
    NotificationPreview,
    HandleReadingIntegerNumber,
    DeleteGeneralDocument,
    GeneralDocumentPreview,
    GeneralDocumentDetails,
    ShowLearnMore,
    UploadGeneralDocument,
    ImageViewer,
    TenantRentingPeriods,
    SendTenantNotification,
    RentingPeriodStatus,
    AddBankAccount,
    EditBankAccount,
    DeleteBankAccount,
    BankAccountDetails,
    BankAccountProperties,
    DeletePropertyFromBankAccount,
    EditPropertyGroupPercentage,
    PropertyGroupDetails,
    ExpensePayment,
    AddCardPaymentData,
    HandleProviderAutomaticSendToTenant,
    TenantPayment,
    SendLoginCodeBySMS,
    ActiveUserDetailsSheet,
    EditActiveUserPhone,
    ExpenseAIExtractorUpload,
    AddObservation,
    EditObservation,
    DeleteObservation,
    ObservationDetails,
    ObservationsList,
    HandleActiveUserAccountType,
    SyncProperties,
    UpdatePropertyFeaturesAndUtilities,
    EditPropertyOwnerDetails,
}

interface State {
    modalOpen: AppModals | undefined
    context: AppContext | undefined
    currentPropertyId: number | undefined
    currentGroupId: number | undefined
    isAddingMeter: boolean
    editingItem: any | undefined
    showSubscriptionPage: boolean
    errorMessage: string | undefined
    currentRentingPeriodId: number | undefined
    isRedirectPaymentButtonPressed: boolean
}

const initialState: State = {
    modalOpen: undefined,
    currentPropertyId: localStorage.getItem("currentPropertyId") ? Number(localStorage.getItem("currentPropertyId")) : undefined,
    currentGroupId: localStorage.getItem("currentGroupId") ? Number(localStorage.getItem("currentGroupId")) : undefined,
    context:
        localStorage.getItem("appContext") != null ? (localStorage.getItem("appContext") === "0" ? AppContext.Owner : AppContext.Tenant) : undefined,
    isAddingMeter: false,
    editingItem: undefined,
    showSubscriptionPage: false,
    errorMessage: undefined,
    currentRentingPeriodId: localStorage.getItem("currentRentingPeriodId") ? Number(localStorage.getItem("currentRentingPeriodId")) : undefined,
    isRedirectPaymentButtonPressed: false,
}

const appStateSlice = createSlice({
    name: "appState",
    initialState,
    reducers: {
        setModalOpen: (state, action: PayloadAction<AppModals | undefined>) => {
            state.modalOpen = action.payload
        },
        setEditingItem: (state, action: PayloadAction<any>) => {
            state.editingItem = action.payload
        },
        setCurrentPropertyId: (state, action: PayloadAction<number | undefined>) => {
            if (action.payload != null) localStorage.setItem("currentPropertyId", action.payload.toString())
            else localStorage.removeItem("currentPropertyId")
            state.currentPropertyId = action.payload
        },
        setCurrentGroupId: (state, action: PayloadAction<number | undefined>) => {
            if (action.payload != null) localStorage.setItem("currentGroupId", action.payload.toString())
            else localStorage.removeItem("currentGroupId")
            state.currentGroupId = action.payload
        },
        setContext: (state, action: PayloadAction<AppContext | undefined>) => {
            state.context = action.payload
            state.currentPropertyId = undefined
            localStorage.removeItem("currentPropertyId")
            if (action.payload != null) {
                Api.setContext(action.payload)
                localStorage.setItem("appContext", action.payload.toString())
            } else localStorage.removeItem("appContext")
        },
        setShowSubscriptionPage: (state, action: PayloadAction<boolean>) => {
            state.showSubscriptionPage = action.payload
        },
        setErrorMessage: (state, action: PayloadAction<string | undefined>) => {
            state.errorMessage = action.payload
        },
        setCurrentRentingPeriodId: (state, action: PayloadAction<number | undefined>) => {
            if (action.payload != null) localStorage.setItem("currentRentingPeriodId", action.payload.toString())
            else localStorage.removeItem("currentRentingPeriodId")
            state.currentRentingPeriodId = action.payload
        },
        setIsRedirectPaymentButtonPressed: (state, action: PayloadAction<boolean>) => {
            state.isRedirectPaymentButtonPressed = action.payload
        },
    },
})

export const {
    setEditingItem,
    setErrorMessage,
    setModalOpen,
    setContext,
    setCurrentPropertyId,
    setShowSubscriptionPage,
    setCurrentGroupId,
    setCurrentRentingPeriodId,
    setIsRedirectPaymentButtonPressed,
} = appStateSlice.actions
export default appStateSlice.reducer
